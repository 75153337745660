/** ---------------------------- ORDER CREATION PAGE HEADER ---------------------------- **/

.orderCreationPage-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orderCreationPage-header-title {
  margin-left: 15px;
  padding-left: 5px;
  font-weight: lighter;
}

/** ---------------------------- ORDER CREATION PAGE BODY ---------------------------- **/

.orderCreationPage-billing-grid {
  .Mui-error {
    color: rgba(255, 82, 82, 1);
    font-weight: bold;
  }
}

.orderCreationPage-billing-footer-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-control-order-creation-cards input {
  text-align: center;
  padding: 5px;
}

.OrderCreationPage-paper-container {
  border-color: rgb(142 205 255) !important;
  border-radius: 5px !important;
  height: 100%;
}

.datepicker {
  input {
    color: rgba(255, 82, 82, 1);
  }

  svg {
    fill: rgba(33, 150, 243, 1);
  }
}


/** MEDIA QUERIES POUR LA BILLING BOX STICKY DE ORDER CREATION PAGE **/
@media (min-height: 950px) {
  .order-creation-billing-grid-responsive {
    height: 75%;
    overflow: auto;
  }

  .orderCreationPage-billing-footer-container {
    //height: 100%;
  }

  .order-creation-billing-grid-footer-responsive {
    height: 25%;
  }

  .order-creation-billing-grid-container-responsive {
    height: 72vh !important;
  }

  .box-textfield-order-creation {
    margin: 5px;
    width: 100%;
  }
}

@media (max-height: 949px) {
  .order-creation-billing-grid-responsive {
    height: 72%;
    overflow: auto;
  }

  .orderCreationPage-billing-footer-container {
    //height: 100%;
  }

  .order-creation-billing-grid-footer-responsive {
    height: 30%;
  }

  .order-creation-billing-grid-container-responsive {
    //height: 78vh !important;
    height: 72vh !important;
  }

  .box-textfield-order-creation {
    margin: 5px;
    width: 100%;
  }
}

@media (max-height: 890px) {
  .order-creation-billing-grid-responsive {
    height: 69%;
    overflow: auto;
  }

  .orderCreationPage-billing-footer-container {
    //height: 100%;
  }

  .order-creation-billing-grid-footer-responsive {
    height: 30%;
  }

  .order-creation-billing-grid-container-responsive {
    //height: 75vh !important;
    height: 72vh !important;
  }

  .box-textfield-order-creation {
    margin: 5px;
    width: 50%;
  }
}

@media (max-height: 820px) {
  .order-creation-billing-grid-responsive {
    height: 66%;
    overflow: auto;
  }
  .orderCreationPage-billing-footer-container {
    //height: 100%;
  }

  .order-creation-billing-grid-footer-responsive {
    height: 35%;
  }

  .order-creation-billing-grid-container-responsive {
    height: 72vh !important;
  }

  .box-textfield-order-creation {
    margin: 3px;
    width: 50%;
  }

}

@media (max-height: 700px) {
  .order-creation-billing-grid-responsive {
    height: 59%;
    overflow: auto;
  }

  .orderCreationPage-billing-footer-container {
    //height: 100%;
  }

  .order-creation-billing-grid-footer-responsive {
    height: 40%;
  }

  .order-creation-billing-grid-container-responsive {
    height: 66vh !important;
  }

  .box-textfield-order-creation {
    margin: 5px;
    flex: 1;
  }
}

@media (max-height: 650px) {
  .order-creation-billing-grid-responsive {
    height: 54%;
    overflow: auto;
  }

  .order-creation-billing-grid-footer-responsive {
    height: 40%;
  }

  .order-creation-billing-grid-container-responsive {
    min-height: 64vh !important;
  }

  .box-textfield-order-creation {
    margin: 3px;
  }
}