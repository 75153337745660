.search {
  margin-left: 10px;
  margin-right: 10px;

  svg {
    fill: gray;
  }

  &:hover svg {
    fill: rgba(33, 150, 243, 1);
    transition: 0s;
  }
}

.Mui-focused svg {
  fill: rgba(33, 150, 243, 1);
}