.card-content {
  padding-left: 5px;
}

.detail-content-card {
  padding: 10px 10px 0 10px;
  height: 100%;
}

.main-card {
  height: 100%;
}

.img-title-card {
  margin-right: 8px;
  width: 32px !important;
  height: 32px !important;

  svg {
    fill: rgba(33, 150, 243, 1) !important;
  }
}

.special-wrapper-item-detailed-header {
  display: flex;
}

.detail-content-card-with-picture {
  border-radius: 5px;
  height: 265px;
  background-size: cover;
  object-fit: cover;
}

.flexbox-center-container {
  display: flex;
  justify-content: center;
}