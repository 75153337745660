/** ---------------------------- ORDER LIST PAGE HEADER ---------------------------- **/

.orderListPage-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.orderListPage-header-title {
  margin: 0;
  letter-spacing: 1px;
  font-weight: 300;
  //font-size: var(--fontSize-400) !important;
}

.orderListPage-header-title-data {
  margin: 0;
  letter-spacing: 1px;
  font-weight: 300;
  font-size: var(--fontSize-400) !important;
}

.orderListPage-header-buttons-container {
  padding-right: 15px;
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin-right: 5px !important;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.orderListPage-header-button {
  background: rgba(33, 150, 243, 1);
  font-size: 1rem;

  & svg {
    margin-right: 6px;
    fill: white;
  }
}

/** ---------------------------- ORDER LIST PAGE BODY ---------------------------- **/