* {
  list-style: none;
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: var(--fontSize-400);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --fontSize-400: clamp(1rem, 1vw + 1rem, 5rem);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

a {
  text-decoration: none; /* no underline */
  color: rgba(33, 150, 243, 1);
}

.style-hr-blue {
  width: 100%;
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(33, 150, 243, 1), rgba(0, 0, 0, 0));
}

.style-hr-grey {
  width: 100%;
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgb(110, 110, 110), rgba(0, 0, 0, 0));
}

.field-value {
  margin-bottom: 10px;
  display: flex;

  &:last-child {
    margin-bottom: 0;
  }
}

.field-value-no-bottom-margin {
  margin-bottom: 0;
  display: flex;

  &:last-child {
    margin-bottom: 0;
  }
}

.text-field-value {
  margin-left: 10px;
  color: rgba(255, 82, 82, 1);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-lower-first-letter-up {
  text-transform: lowercase;

  &:first-letter {
    text-transform: uppercase;
  }
}

.text-field-label {
  margin-right: 3px !important;
}

.text-field-label-bold {
  margin-right: 3px;
  color: black;
  font-weight: bolder;
}

.box-flex-column-align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.field-data {
  color: rgba(255, 82, 82, 1);
}

.title-div {
  font-weight: bolder;
}

/** RIB **/
.rib-paper-container {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 56px;
  align-items: center;
}

.item-list-card-card-content {
  padding: 10px;

  &:last-child {
    padding-bottom: 10px !important;
  }
}

.a_user_manual_guide_light_theme {
  color: white !important;
}

.a_user_manual_guide_dark_theme {
  color: black !important;
}

.item-card-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}