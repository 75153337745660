/** ---------------------------- ITEM LIST PAGE HEADER ---------------------------- **/

.itemListPage-header-buttons-container {
  padding-right: 15px;
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin-right: 5px !important;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.itemListPage-header-title {
  padding-left: 15px;
  margin: 0;
  letter-spacing: 1px;
  font-weight: 300;
  font-size: var(--fontSize-400);
}

/** ---------------------------- ITEM LIST PAGE ---------------------------- **/

.items-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 10px;
  padding: 10px;
}

.item-individual-container {
  width: 270px;
  margin: 16px;
}

/** ITEM LIST HEADER **/

.itemListPage-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.itemListPage-header-button {
  background-color: rgba(33, 150, 243, 1) !important;

  & svg {
    margin-right: 6px;
    fill: white;
  }
}