.logo {
  display: flex;
  width: 140px;
  height: 80px;
}

.appbar-style {
  box-shadow: 0 0 10px 3px #454545;

  .items-links-with-icons {
    a {
      position: relative;
      padding: 16px 0;
      margin: 0 12px;
      letter-spacing: 1px;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;

      color: #000;
      text-decoration: none;

      &:hover, &:focus, &:active {
        color: rgba(33, 150, 243, 1);
        transition: color 0.1s, background-color 0.1s, padding 0.2s ease-in;

        svg {
          fill: rgba(33, 150, 243, 1);
        }
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 3px;
        left: 0;
        height: 3px;
        width: 100%;
        background-color: rgba(33, 150, 243, 1);
        transform-origin: right top;
        transform: scale(0, 1);
        transition: color 0.1s, transform 0.2s ease-out;
      }

      &:hover::before, &:focus::before {
        transform-origin: left top;
        transform: scale(1, 1);
      }
    }
  }
}

.menu-item-with-icon {
  display: flex;
  align-items: center;

  span {
    margin-left: 4px;
  }
}

.navbar-avatar-icon:hover {
  border: 1px solid rgba(33, 150, 243, 1) !important;

  & svg {
    fill: rgba(33, 150, 243, 1);
  }
}

.navbar-menu-item-container, .menu-item-with-icon {
  a {
    color: rgba(33, 150, 243, 1)
  }
}


/** NAVBAR ACTIVE LINKS DEFAULT LARGE SCREEN **/
.navigation {
  ul {
    display: flex;

    a {
      text-decoration: none;
      padding: 10px;
      letter-spacing: 1px;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;

      li {
        transition: 0.2s;
        cursor: pointer;
        display: flex;
        align-items: center;
      }
    }

    .nav-active {
      position: relative;

      li::after {
        content: "";
        height: 4px;
        width: 0;
        background: rgba(33, 150, 243, 1);
        position: absolute;
        border-radius: 10px;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        -webkit-animation: anim 0.3s ease forwards;
        animation: anim 0.6s ease forwards;

        @-webkit-keyframes anim {
          to {
            width: 100%;
          }
        }
        @keyframes anim {
          to {
            width: 100%;
          }
        }
      }
    }
  }
}

/** NAVBAR ACTIVE LINKS RESPONSIVE SCREEN **/
.navigationResponsive {
  width: 100%;

  &:hover {
    color: rgba(33, 150, 243, 1);
  }

  ul {
    display: flex;
    padding-inline-start: 0px;

    a {
      text-decoration: none;
      padding: 5px;
      letter-spacing: 1px;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;

      li {
        transition: 0.2s;
        cursor: pointer;
        display: flex;
        align-items: center;

        .nav-activeResponsive {
          position: relative;

          li::after {
            content: "";
            height: 4px;
            width: 0;
            background: rgba(33, 150, 243, 1);
            position: absolute;
            border-radius: 10px;
            bottom: 0px;
            left: 50%;
            transform: translateX(-50%);
            -webkit-animation: anim 0.3s ease forwards;
            animation: anim 0.6s ease forwards;

            @-webkit-keyframes anim {
              to {
                width: 100%;
              }
            }
            @keyframes anim {
              to {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}